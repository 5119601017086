import React from "react"
import Layout from "../../components/layout"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import CAF48R from "../resources/CERTIFICATE OF COMPLIANCE CAF48R-4.pdf"
import RFR from "../resources/CAF 2 Recommended Flow Rate Test Procedure  R20134.pdf"
import PSFTP from "../resources/CAF 3 Particulate System  Filter Test Procedure R20134.pdf"
import VTP from "../resources/CAF 6 Vapor Test Procedure R20204.pdf"
import { Helmet } from "react-helmet"
import data from "../../data/testing"
import { ReactComponent as Random } from "../../styles/icons/random-solid.svg"
import { ReactComponent as Chart } from "../../styles/icons/chart-bar-solid.svg"
import { ReactComponent as Book } from "../../styles/icons/address-book-solid.svg"
import pages from "../../data/navItems"

export default function Testing() {
  const { section1, section2, middleSection } = data.content
  const images = useStaticQuery(graphql`
    query testingApproval {
      testing: file(relativePath: { eq: "Testing-Approval.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
          fixed(width: 450) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      testingM: file(relativePath: { eq: "Testing-Approval.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      workshop: file(relativePath: { eq: "workshop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
          fixed(width: 500) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${data.title} | Clean Air Filter`}</title>
      </Helmet>
      <section>
        <h1>
          <span className="tail">
            <span className="head">{section1.h1.head}</span> {section1.h1.tail}
          </span>
        </h1>
        <article className="w-60">
          <p>{section1.p1}</p>
          {/* <p>{section1.p2}</p> */}
          <p>{section1.p3}</p>
          <p>{section1.p4}</p>

          <div className="resources-links" style={{ display: "block" }}>
            <p style={{ marginBottom: "0.25rem" }}>
              <Link className="primary red" to="/contact">
                {section1.a1}
              </Link>
            </p>
            <p style={{ marginBottom: "0.25rem" }}>
              <a
                className="download-link"
                href={RFR}
                target="_blank"
                rel="noreferrer"
              >
                {section1.a2}
              </a>
            </p>
            <p style={{ marginBottom: "0.25rem" }}>
              <a
                className="download-link"
                href={PSFTP}
                target="_blank"
                rel="noreferrer"
              >
                {section1.a3}
              </a>
            </p>
            <p style={{ marginBottom: "0.25rem" }}>
              <a
                className="download-link"
                href={VTP}
                target="_blank"
                rel="noreferrer"
              >
                {section1.a4}
              </a>
            </p>
            {/* <p style={{ marginBottom: '0.25rem' }}><a className="download-link" href={WC55} target="_blank" rel="noreferrer">{section1.a3}</a></p> */}
          </div>
        </article>
      </section>

      <hr></hr>

      {/* <section>
          <div className="float-right" style={{ marginBottom: "2rem" }}>
            <Img fluid={images.workshop.childImageSharp.fluid}></Img>
          </div>
          <article className="w-60">
            <span style={{ display: "flex" }}>
              <Random></Random>&#160;<h2>{middleSection.article1.h2}</h2>
            </span>
            <p
              dangerouslySetInnerHTML={{ __html: middleSection.article1.p }}
            ></p>
            <p>
              <Link className="primary red" to="/testing/how-it-works">
                How It Works
              </Link>
            </p>
          </article>
        </section> */}

      <article className="w-60">
        <section>
          <div className="float-right" style={{ marginBottom: "2rem" }}>
            <Img fluid={images.workshop.childImageSharp.fluid}></Img>
          </div>
          <span style={{ display: "flex" }}>
            <Random style={{ minWidth: 32 }}></Random>&#160;
            <h2>{middleSection.article1.h2}</h2>
          </span>
          <p>{middleSection.article1.p}</p>
          <p>
            <Link
              className="primary red"
              to={pages[1].to + pages[1].children[0].to}
            >
              {middleSection.article1.h2}
            </Link>
          </p>
        </section>

        <section>
          <span style={{ display: "flex" }}>
            <Chart style={{ minWidth: 32 }}></Chart>&#160;
            <h2>{middleSection.article2.h2}</h2>
          </span>
          <p>{middleSection.article2.p}</p>
          <p>
            <Link
              className="primary red"
              to={pages[1].to + pages[1].children[1].to}
            >
              {middleSection.article2.h2}
            </Link>
          </p>
        </section>
      </article>
      <hr></hr>

      {/* <article className="w-60">
            <section>
                <h2>{section2.h2}</h2>
                <p>{section2.p1}</p>
                <p>{section2.p2}</p>
                <p>{section2.p3}</p>
                <p>{section2.p4}</p>
                <p>{section2.p5}</p>
                <p>{section2.p6}</p>
                <p>{section2.p7}</p>
                <p>{section2.p8}</p>
                <p>{section2.p9}</p>
                <p>{section2.p10}</p>
                <p>{section2.p11}</p>
                <p>{section2.p12}</p>
                <p>{section2.p13}</p>
            </section>
        </article> */}
    </Layout>
  )
  // }}
  // >
  // </StaticQuery>;
}
